import { FC, useCallback, useMemo } from 'react';
import { Divider, Size } from '@headspace/web-ui-components';
import { useSelector, useDispatch } from 'react-redux';

import { distanceInWords, makeFriendlyDate } from '../../../../utils/dates';
import { Input } from '../../../../shared-components/Input';
import { DependentsInputGroup } from '../../../../shared-components/DependentsInputGroup';
import { FileUploader } from '../../../../shared-components/FileUploader';
import { TitleXS } from '../../../../shared-components/text/index';
import { OrganizationAdministratorsDisplay } from '../../OrganizationAdministrators/OrganizationAdministratorsDisplay';
import {
  rowStyle,
  headerContainerStyle,
  reviewPageContainerStyle,
  subheaderStyle,
} from '../styles/createOrganizationStyles';

import { HOST } from '../../../../utils/constants';
import { getSelectTitle } from '../../../../utils';
import {
  getOrgManagement,
  getOverflowDisabledStatus,
} from '../../../../state/selectors/organizationSelectors';
import { navigateTo } from '../../../../state/actions/organizationActions';
import { EditButton } from './EditButton';
import env from '../../../../config/env';
import { useCsvDelimiter } from '../../../../featureFlags/csvDelimiter';
import { ORG_CREATION_STEPS, PRIMARY_PRODUCT } from '../../constants';
import { OrgMappingKeyInput } from '../../OrganizationView/SettingsView/components/OrgHierarchyPanel/components/OrgMappingKeyInput';
import { OrgMappingValueInput } from '../../OrganizationView/SettingsView/components/OrgHierarchyPanel/components/OrgMappingValueInput';
import { ParentOrgInput } from '../../OrganizationView/SettingsView/components/shared-components/ParentOrgInput/ParentOrgInput';
import { useTranslate } from '../../../../hooks/useTranslate';
import {
  EapTypes,
  RestrictBy,
  delimiterOptions,
} from '../../../../types/organization';
import {
  shouldShowCampaignDisplayFields,
  isZeroInput,
} from '../../utils/manage-organization';
import { isCSM as getIsCSM } from '../../../../state/selectors/userSelectors';
import { OrgCountrySelect } from '../../OrganizationView/SettingsView/components/shared-components/OrgCountrySelect/OrgCountrySelect';
import { AddOnInput } from '../../../../shared-components/AddOnInput/AddOnInput';

const noPromoFlagged = env.FEATURE_FLAGS.includes('NO_PROMO_EMAILS');

export const Review: FC = () => {
  const {
    campaignDisplayName,
    campaignLaunchDate,
    logoFileName,
    logoFilePreview,
    logoFileError,
    org_name,
    org_salesforce_id,
    org_type,
    no_promo_emails,
    healthHub,
    healthHubExternalId,
    gingerId,
    current_contract_end_date,
    current_contract_family_members_per_employee,
    current_contract_is_pilot,
    current_contract_number_of_family_members_seats,
    current_contract_opportunity_id,
    current_contract_payment_type,
    current_contract_primary_product,
    current_contract_seats,
    current_contract_start_date,
    current_contract_voucher,
    org_admins,
    slug,
    restrictBy,
    overflow_url,
    learn_more_url_path,
    restrictedDomains,
    workIdFieldPlaceholderText,
    mfaEnabled,
    parentOrg,
    externalOrgId,
    add_ons,
    current_contract_add_ons,
    phoneNumber,
    provider,
    serviceType,
    current_contract_eligibility_file_based_dependents,
    csvDelimiter,
    coverageTotal,
  } = useSelector(getOrgManagement);
  const isCSM = useSelector(getIsCSM);
  const overflowDisabled = useSelector(getOverflowDisabledStatus);
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const showCampaignDisplayFields = shouldShowCampaignDisplayFields(
    restrictBy,
    isCSM,
  );
  const isSupplementalDetailsStepEnabled = useMemo(
    () =>
      (current_contract_add_ons ||
        parentOrg?.contracts[0]?.eapType === EapTypes.EAP_V2) &&
      current_contract_primary_product === PRIMARY_PRODUCT.FUSION,
    [
      current_contract_add_ons,
      current_contract_primary_product,
      parentOrg?.contracts,
    ],
  );

  const updatedSlug = parentOrg?.useSingleEnrollmentLink
    ? parentOrg?.enrollment?.slug
    : slug;

  const isRestrictedByEmpId = [
    RestrictBy.EMPLOYEE_ID,
    RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE,
  ].includes(restrictBy as RestrictBy);
  const isRestrictedByEmail = restrictBy === RestrictBy.EMAIL_ELIGIBILITY_FILE;

  const displayContract = parentOrg?.contracts[0]
    ? {
        dependentSeats: parentOrg.contracts[0].dependentSeats,
        dependentsPerMember: parentOrg.contracts[0].dependentsPerMember,
        elfBasedDependents: parentOrg.contracts[0].elfDependentsEnabled,
        endDate: parentOrg.contracts[0].endDate,
        inviteBasedDependents: !isZeroInput(
          parentOrg.contracts[0].dependentSeats,
        ),
        isPilot: parentOrg.contracts[0].isPilot,
        opportunityId: parentOrg.contracts[0].opportunityId,
        partialPayVoucher: parentOrg.contracts[0].partialPayVoucher,
        seats: parentOrg.contracts[0].seats,
        startDate: parentOrg.contracts[0].startDate,
      }
    : {
        dependentSeats: current_contract_number_of_family_members_seats,
        dependentsPerMember: current_contract_family_members_per_employee,
        elfBasedDependents: current_contract_eligibility_file_based_dependents,
        endDate: current_contract_end_date,
        inviteBasedDependents: !isZeroInput(
          current_contract_number_of_family_members_seats,
        ),
        isPilot: current_contract_is_pilot,
        opportunityId: current_contract_opportunity_id,
        partialPayType: current_contract_payment_type,
        partialPayVoucher: current_contract_voucher,
        seats: current_contract_seats,
        startDate: current_contract_start_date,
      };

  const startDate = displayContract.startDate
    ? makeFriendlyDate(new Date(displayContract.startDate).toISOString())
    : '';
  const endDate = displayContract.endDate
    ? makeFriendlyDate(new Date(displayContract.endDate).toISOString())
    : '';
  const host = HOST ? HOST.replace(/(https|http):\/\/(www\.)?/, '') : '';

  const handleStep = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const { id } = event.target as HTMLBaseElement;
      dispatch(navigateTo(id as ORG_CREATION_STEPS));
    },

    [dispatch],
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleStep(e);
      }
    },
    [handleStep],
  );

  const noTranslate = translate('no');
  const addOnsTranslateLabel = translate(
    'organizationSettings.enrollment.addOnsLabel',
  );
  const addOnsValue = translate(
    'organizationSettings.enrollment.addOnsValue.eap',
  );
  const externalOrgIdLabel = translate(
    'organizationSettings.basic.externalOrgIdLabel',
  );
  const phoneNumberLabel = translate(
    'organizationSettings.basic.phoneNumberLabel',
  );
  const providerLabel = translate('organizationSettings.basic.provider');
  const serviceTypeLabel = translate('organizationSettings.basic.serviceType');
  const serviceTypeValue = serviceType
    ? translate(
        `organizationSettings.basic.serviceType.${serviceType.toLocaleLowerCase()}`,
      )
    : '';
  const coverageTotalLabel = translate(
    'organizationSettings.basic.coverageTotalLabel',
  );

  const [csvDelimiterFeatureEnabled] = useCsvDelimiter();

  const parentEapType = parentOrg?.contracts?.find(
    (contract) => contract.eapType === EapTypes.EAP_V2,
  );

  return (
    <div data-testid="review-page" css={reviewPageContainerStyle}>
      <div css={headerContainerStyle}>
        <TitleXS>{translate(`organizationSettings.basic.title`)}</TitleXS>
        <EditButton
          id={ORG_CREATION_STEPS.BASIC}
          onClick={handleStep}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div css={rowStyle}>
        <div>
          <Input
            type="review"
            name="current_contract_primary_product"
            label={translate(`organizationSettings.basic.productTypeLabel`)}
            value={translate(
              `organizationSettings.basic.productTypes.${current_contract_primary_product}`,
            )}
          />
          <Input
            type="review"
            name="org_name"
            label={translate(`organizationSettings.basic.nameLabel`)}
            value={org_name}
          />
          <OrgCountrySelect editMode={false} />
          <Input
            type="review"
            name="org_salesforce_id"
            label={translate(`organizationSettings.basic.salesforceIdLabel`)}
            value={org_salesforce_id}
          />
          <Input
            type="review"
            name="gingerId"
            label={translate(`organizationSettings.basic.gingerIdLabel`)}
            value={gingerId || ''}
          />
          <Input
            type="review"
            name="org_type"
            label={translate(`organizationSettings.basic.typeLabel`)}
            value={translate(`organizationSettings.basic.types.${org_type}`)}
          />
          <Input
            type="review"
            name="mfaEnabled"
            label={translate('organizationSettings.basic.mfaEnabledLabel')}
            value={mfaEnabled ? translate('yes') : translate('no')}
          />
          {!noPromoFlagged && (
            <Input
              type="review"
              name="no_promo_emails"
              label={translate(`organizationSettings.basic.noPromoEmailsLabel`)}
              value={no_promo_emails ? translate('yes') : translate('no')}
            />
          )}
          <div>
            <Input
              name="healthHub"
              type="review"
              label="HEALTH HUB"
              value={healthHub || ''}
            />
            {!!healthHub && (
              <Input
                type="review"
                name="healthHubExternalId"
                placeholder="ID"
                label="HEALTH HUB EXTERNAL ID"
                value={healthHubExternalId || ''}
              />
            )}
          </div>
          <Input
            type="review"
            name="add_ons"
            label={addOnsTranslateLabel}
            value={add_ons ? addOnsValue : noTranslate}
            dataTestId="add_ons"
          />
          {add_ons && (
            <>
              <div css={rowStyle}>
                <Input
                  type="review"
                  name="externalOrgId"
                  label={externalOrgIdLabel}
                  value={externalOrgId}
                  dataTestId="externalOrgId"
                />
                <Input
                  type="review"
                  name="phoneNumber"
                  label={phoneNumberLabel}
                  value={phoneNumber}
                  dataTestId="phoneNumber"
                />
              </div>
              <div css={rowStyle}>
                <Input
                  type="review"
                  name="provider"
                  label={providerLabel}
                  value={provider}
                  dataTestId="provider"
                />
                <Input
                  type="review"
                  name="serviceType"
                  label={serviceTypeLabel}
                  value={serviceTypeValue}
                  dataTestId="serviceType"
                />
              </div>
            </>
          )}
        </div>
        <div>
          <FileUploader
            view={true}
            label={translate(`organizationSettings.basic.logoLabel`)}
            fileError={logoFileError}
            fileName={logoFileName}
            filePreview={logoFilePreview}
            disabled={true}
          />
        </div>
      </div>
      <Divider size={Size.XL} />
      <div css={headerContainerStyle}>
        <TitleXS>
          {translate(`organizationSettings.orgHierarchy.title`)}
        </TitleXS>
        <EditButton
          id={ORG_CREATION_STEPS.ORG_HIERARCHY}
          onClick={handleStep}
          onKeyPress={handleKeyPress}
        />
      </div>
      <ParentOrgInput editMode={false} />
      <OrgMappingKeyInput editMode={false} />
      <OrgMappingValueInput editMode={false} />
      <Divider size={Size.XL} />
      <div css={headerContainerStyle}>
        <TitleXS>{translate(`organizationSettings.contract.title`)}</TitleXS>
        {parentOrg === null ? (
          <EditButton
            id={ORG_CREATION_STEPS.CONTRACT}
            onClick={handleStep}
            onKeyPress={handleKeyPress}
          />
        ) : null}
      </div>
      {parentOrg !== null ? (
        <div css={subheaderStyle}>
          {translate('organizationSettings.orgHierarchy.inheritedFromParent')}
        </div>
      ) : null}
      <Input
        type="review"
        name="opportunity_id"
        label={translate(`organizationSettings.contract.opportunityIdLabel`)}
        value={displayContract.opportunityId}
      />
      <div>
        <div css={rowStyle}>
          <Input
            type="review"
            name="start_date"
            label={translate(`organizationSettings.contract.startLabel`)}
            value={startDate || displayContract.startDate}
            subtext={
              Date.parse(displayContract.startDate)
                ? distanceInWords(
                    new Date(),
                    new Date(displayContract.startDate),
                    true,
                  )
                : translate('validationErrors.NOT_A_DATE')
            }
          />
          <Input
            type="review"
            name="end_date"
            label={translate(`organizationSettings.contract.endLabel`)}
            value={endDate || displayContract.endDate}
            subtext={
              Date.parse(displayContract.endDate)
                ? distanceInWords(
                    new Date(),
                    new Date(displayContract.endDate),
                    true,
                  )
                : translate('validationErrors.NOT_A_DATE')
            }
          />
        </div>
        <div css={rowStyle}>
          <Input
            type="review"
            name="seats"
            label={translate(`organizationSettings.contract.seatsLabel`)}
            value={displayContract.seats}
          />
          <DependentsInputGroup
            name="dependent-setting"
            inviteBasedDependents={displayContract.inviteBasedDependents}
            eligibilityFileBasedDependents={displayContract.elfBasedDependents}
            isViewMode={true}
            contract="current"
          />
        </div>
        <div css={rowStyle}>
          {displayContract.inviteBasedDependents && (
            <>
              <Input
                type="review"
                name="seats"
                label={translate(
                  `organizationSettings.contract.familyMembersPerEmployeeLabel`,
                )}
                value={displayContract.dependentsPerMember}
              />
              <Input
                type="review"
                name="current_contract_number_of_family_members_seats"
                label={translate(
                  `organizationSettings.contract.familyMembersSeatsLabel`,
                )}
                value={displayContract.dependentSeats}
              />
            </>
          )}
        </div>
        <Input
          type="review"
          name="payment_type"
          label={translate(`organizationSettings.contract.paymentLabel`)}
          value={translate(
            `organizationSettings.contract.paymentOptions.${
              displayContract.partialPayType || ''
            }`,
          )}
        />
        <div css={rowStyle}>
          <Input
            type="review"
            name="is_pilot"
            label={translate(`organizationSettings.contract.pilotLabel`)}
            value={displayContract.isPilot ? translate('yes') : translate('no')}
          />
          {current_contract_payment_type && (
            <Input
              type="review"
              name="voucher"
              label={translate(`organizationSettings.contract.voucherCode`)}
              value={displayContract.partialPayVoucher}
            />
          )}
          <AddOnInput
            name="current_contract_add_ons"
            type="review"
            value={
              current_contract_add_ons || parentEapType
                ? addOnsValue
                : noTranslate
            }
          />
        </div>
      </div>
      <Divider size={Size.XL} />
      {isSupplementalDetailsStepEnabled && (
        <>
          <div
            css={headerContainerStyle}
            data-testid="supplemental-details-section"
          >
            <TitleXS>
              {translate(`organizationSettings.supplementalDetails.title`)}
            </TitleXS>
            <EditButton
              id={ORG_CREATION_STEPS.SUPPLEMENTAL_DETAILS}
              onClick={handleStep}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div css={rowStyle}>
            <Input
              type="review"
              name="externalOrgId"
              label={translate(
                `organizationSettings.supplementalDetails.eap.externalOrgIdLabel`,
              )}
              value={externalOrgId}
            />
            <Input
              type="review"
              name="phoneNumber"
              label={translate(
                `organizationSettings.supplementalDetails.eap.phoneNumberLabel`,
              )}
              value={phoneNumber}
            />
          </div>
          <div css={rowStyle}>
            <Input
              type="review"
              name="provider"
              label={translate(
                `organizationSettings.supplementalDetails.clinicalServices.provider`,
              )}
              value={provider}
            />
            <Input
              type="review"
              name="serviceType"
              label={translate(
                `organizationSettings.supplementalDetails.clinicalServices.serviceType`,
              )}
              value={serviceType}
            />
          </div>
          <div css={rowStyle}>
            <Input
              type="review"
              name="coverageTotal"
              label={coverageTotalLabel}
              value={coverageTotal}
              dataTestId="coverageTotal"
            />
          </div>
        </>
      )}
      <Divider size={Size.XL} />
      <div css={headerContainerStyle}>
        <TitleXS>
          {translate(`organizationSettings.administrators.title`)}
        </TitleXS>
        <EditButton
          id={ORG_CREATION_STEPS.ADMINISTRATORS}
          onClick={handleStep}
          onKeyPress={handleKeyPress}
        />
      </div>
      <OrganizationAdministratorsDisplay admins={org_admins} />
      <Divider size={Size.XL} />
      <div css={headerContainerStyle}>
        <TitleXS>{translate(`organizationSettings.enrollment.title`)}</TitleXS>
        <EditButton
          id={ORG_CREATION_STEPS.ENROLLMENT}
          onClick={handleStep}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div>
        <div css={rowStyle}>
          <Input
            type="review"
            name="slug"
            label={translate(`organizationSettings.enrollment.slugLabelSimple`)}
            value={`${host}/${updatedSlug}/member-enroll`}
          />
          <Input
            type="review"
            name="overflow_url"
            label={translate(`organizationSettings.enrollment.overflowLabel`)}
            value={
              overflowDisabled ? translate('disabled') : overflow_url || 'N/A'
            }
          />
        </div>
        <div css={rowStyle}>
          <Input
            type="review"
            name="restrictBy"
            label={translate(`organizationSettings.enrollment.restrictByLabel`)}
            value={translate(`organizationSettings.enrollment.${restrictBy}`)}
            subtext={
              restrictBy === RestrictBy.EMAIL_DOMAIN
                ? `${restrictedDomains
                    .slice(0, 2)
                    .map((domain) => `@${domain}`)
                    .join(', ')}${
                    restrictedDomains.length > 2
                      ? `, +${restrictedDomains.length - 2} ${translate(
                          `organizationSettings.enrollment.moreDomains`,
                        )}`
                      : ''
                  }`
                : ''
            }
          />
          {isRestrictedByEmpId ? (
            <Input
              label={translate(
                `organizationSettings.enrollment.customWorkIdFieldPlaceholderTextTitle`,
              )}
              type="review"
              name="workIdFieldPlaceholderText"
              value={workIdFieldPlaceholderText}
            />
          ) : null}
        </div>
        {csvDelimiterFeatureEnabled &&
        (isRestrictedByEmail || isRestrictedByEmpId) ? (
          <div css={rowStyle}>
            <Input
              dataTestId="csvDelimiterSelect"
              name="csvDelimiter"
              type="review"
              label="delimiter"
              value={getSelectTitle(delimiterOptions, csvDelimiter)}
            />
          </div>
        ) : null}
        <Input
          type="review"
          name="learn_more_url_path"
          label={translate(`organizationSettings.enrollment.infoUrlLabel`)}
          value={`${translate(`organizationSettings.enrollment.infoUrlBase`)}${
            learn_more_url_path ||
            translate(`organizationSettings.enrollment.infoUrlPlaceholder`)
          }`}
        />
      </div>
      {showCampaignDisplayFields && (
        <div css={rowStyle}>
          <Input
            type="review"
            name="campaignDisplayName"
            label={translate(
              `organizationSettings.enrollment.campaignDisplayNameLabel`,
            )}
            value={campaignDisplayName || 'N/A'}
          />
          <Input
            type="review"
            name="campaignLaunchDate"
            label={translate(
              `organizationSettings.enrollment.campaignLaunchDateLabel`,
            )}
            value={campaignLaunchDate || 'N/A'}
          />
        </div>
      )}
    </div>
  );
};
