import { css } from '@emotion/react';
import { cssWithMq, rem, WarmGrey } from '@headspace/web-ui-components';
import { BreakPoint } from '../../../constants/breakpoint';

export const headerStyle = cssWithMq({
  display: 'flex',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  padding: [
    `${rem(1.125)} ${rem(1.5)}`,
    `${rem(1.813)} ${rem(2)}`,
    `${rem(1.813)} ${rem(3)}`,
  ],
  flexDirection: ['column', 'column', 'row'],
});

export const logoContainer = css({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  height: [rem(1.5), rem(1.75), rem(1.5)],
});

export const orgLogoStyle = css({
  maxHeight: rem(3.5),
});

export const logoDividerStyle = css({
  width: rem(0.063),
  height: rem(3.25),
  backgroundColor: WarmGrey[400],
  marginLeft: rem(1.688),
  marginRight: rem(1.688),
});

export const centerStepperStyle = css({
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
  [BreakPoint.Tablet]: {
    position: 'relative',
    left: 'auto',
    right: 'auto',
  },
  // IE 10 and up
  '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
    position: 'static',
  },
  position: 'absolute',
});

export const rightSideStyle = cssWithMq({
  display: 'flex',
  flex: 1,
  paddingRight: [0, 0, rem(1.75)],
  position: 'absolute',
  right: rem(1.25),
  top: [rem(0.625), rem(1.25), rem(1.25)],
});
