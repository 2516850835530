import { useState, useEffect } from 'react';
import { getCookie, setCookie } from '@headspace/web-auth/dist/utils/cookie';
import { CookieKeys } from '../utils/constants';
import { getConfig } from '../config';
import { HubSupportedLanguageCode } from '../types/language';

export const useGetHubLangCookie = (): [
  HubSupportedLanguageCode,
  (lang: string) => void,
] => {
  const initialHubLang = getInitialHubLanguage();
  const [hubLangCookie, setHubLangCookie] = useState(initialHubLang);
  useEffect(() => {
    setCookie(
      CookieKeys.HUB_LANGUAGE,
      hubLangCookie,
      null,
      getConfig().ENROLLMENT_AUTH0_COOKIE.COOKIE_CONFIGURATION,
    );
  }, [hubLangCookie]);
  return [hubLangCookie, setHubLangCookie];
};

const getIsChineseLanguage = ({ primaryLangCode, regionCode }) => {
  if (primaryLangCode === 'zh') {
    if (!regionCode || regionCode === 'CN') return true;
  }
  return false;
};

const getLanguageCodesFromBrowser = () => {
  const browserLanguage = window.navigator.language;
  const [primaryLangCode, regionCode] = browserLanguage.split('-');
  return [primaryLangCode, regionCode];
};

const getInitialHubLanguage = () => {
  const currentStoredHubLang = getCookie(CookieKeys.HUB_LANGUAGE);

  if (currentStoredHubLang) return currentStoredHubLang;
  const [primaryLangCode, regionCode] = getLanguageCodesFromBrowser();
  const isChineseLanguage = getIsChineseLanguage({
    primaryLangCode,
    regionCode,
  });
  if (isChineseLanguage) return HubSupportedLanguageCode.zh_CN;
  if (HubSupportedLanguageCode[primaryLangCode])
    return HubSupportedLanguageCode[primaryLangCode];

  return HubSupportedLanguageCode.en;
};
