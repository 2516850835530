import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { useTranslate } from '../../../../../../../hooks/useTranslate';
import {
  Input,
  InputEventHandler,
} from '../../../../../../../shared-components/Input';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import { CURRENT, PRIMARY_PRODUCT } from '../../../../../constants';
import { isContractProductTypeAllowed } from '../../../../../utils/manage-organization';
import { useFeatureFlag } from '../../../../../../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../../../../../../constants/featureFlags';

export interface PrimaryProductInputProps {
  contractStateTerm: string;
  showReview: boolean;
  onChange?: InputEventHandler;
  disabled?: boolean;
  providedPrimaryProduct?: string;
  errorMessage?: ErrorMessage;
}

export const PrimaryProductInput: FC<PrimaryProductInputProps> = ({
  contractStateTerm,
  showReview,
  onChange,
  disabled,
  providedPrimaryProduct,
  errorMessage,
}) => {
  const { translate } = useTranslate();

  const {
    current_contract_primary_product,
    future_contract_primary_product,
    cachedOrg: { contracts, expiredContracts } = {},
  } = useSelector(getOrgManagement);
  const [unifiedOrgCreation] = useFeatureFlag(
    FEATURE_FLAG.UNIFIED_ORG_CREATION,
  );
  const primaryProduct =
    providedPrimaryProduct ??
    (contractStateTerm === CURRENT
      ? current_contract_primary_product
      : future_contract_primary_product);

  const productOptions: Option[] = Object.keys(PRIMARY_PRODUCT).map(
    (value: string) => ({
      disabled: !isContractProductTypeAllowed(
        PRIMARY_PRODUCT[value],
        {
          contracts,
          expiredContracts,
        },
        unifiedOrgCreation,
      ),
      title: translate(`organizationSettings.basic.productTypes.${value}`),
      value,
    }),
  );

  return (
    <div>
      <Input
        name={`${contractStateTerm}_contract_primary_product`}
        type={showReview ? 'review' : 'select'}
        label={translate(`organizationSettings.basic.productTypeLabel`)}
        value={
          showReview
            ? (translate(
                `organizationSettings.basic.productTypes.${primaryProduct}`,
              ) as string)
            : primaryProduct
        }
        onChange={onChange}
        options={productOptions}
        disabled={disabled}
        hasError={errorMessage?.error}
        errorMessage={errorMessage?.message}
      />
    </div>
  );
};
