import { Dispatch } from 'redux';

import { PRIMARY_PRODUCT } from '../../../apps/dashboard/constants';
import {
  createOrgEAPCoverage,
  createOrganization,
  uploadOrgLogo,
  createOrgClinicalCoverage,
} from '../../../rest';
import { CreateSingleOrganizationPayload } from '../../../rest/organization/organization.types';
import { getOrganizationById } from '../../../rest/organizations';
import { convertDateToUtc, parseDateUtc } from '../../../utils/dates';
import { assureNumberValue } from '../../../utils/typeMassage';
import { getOrgCustomWorkIdFieldPlaceholderText } from '../../selectors/organizationSelectors';
import { ModalActionsTypes } from '../../types/modal';
import {
  OrganizationActionsTypes,
  SaveOrganizationAllFieldsAction,
} from '../../types/organization';
import { OrganizationsActionsTypes } from '../../types/organizations';
import { organizationContractDependentValuesToParams } from '../../utils/dataFormatters';
import {
  organizationOfferingTypesToPrimaryProduct,
  organizationPrimaryProductToOfferingTypes,
} from '../../utils/dataParsers';
import { drain } from './drain';
import { saveOrganizationAdministrators } from './saveOrganizationAdministrators';
import { CLINICAL_COVERAGE_TYPE, EapTypes } from '../../../types/organization';
import { router } from '../../../root-component/router';

export const saveOrganizationAllFields = (): SaveOrganizationAllFieldsAction => {
  // @ts-ignore
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const {
      manageOrganization,
      organizations: { OrganizationList },
    } = state;
    const {
      orgCountry: country,
      org_name: name,
      org_salesforce_id: salesforceId,
      org_type: type,
      sso_enabled: ssoEnabled,
      healthHub,
      healthHubExternalId,
      slug,
      logoFile,
      logoFileError,
      current_contract_seats,
      current_contract_is_pilot: isPilot,
      current_contract_start_date,
      current_contract_end_date,
      current_contract_opportunity_id: opportunityId,
      current_contract_primary_product,
      current_contract_voucher,
      current_contract_payment_type,
      current_contract_family_members_per_employee,
      current_contract_number_of_family_members_seats,
      restrictBy,
      headline,
      body,
      learn_more_url_path,
      restrictedDomains,
      overflow_url,
      gingerId,
      gingerBundlingEnabled,
      mfaEnabled,
      addOns,
      parentOrg,
      parentOrgId,
      orgMappingKey,
      orgMappingValue,
      overlappedCareId,
      campaignDisplayName,
      campaignLaunchDate,
      useSingleEnrollmentLink,
      uploadElfAtChildEnabled,
      phoneNumber,
      externalOrgId,
      provider,
      serviceType,
      coverageTotal,
      current_contract_eligibility_file_based_dependents,
      pii_enrollment_enabled,
      csvDelimiter,
      current_contract_add_ons,
      current_contract_clinical_coverage_type,
    } = manageOrganization;
    const workIdFieldPlaceholderText = getOrgCustomWorkIdFieldPlaceholderText(
      state,
    );
    const startDate = parseDateUtc(current_contract_start_date);
    const endDate = parseDateUtc(current_contract_end_date);
    const campaignLaunchDateUTC = campaignLaunchDate
      ? convertDateToUtc(campaignLaunchDate).toISOString()
      : null;
    let formData: FormData | null = null;
    if (logoFile) {
      formData = new FormData();
      formData.append('logo', logoFile);
    }
    let orgId: number | undefined = void 0;

    const dependentValues = organizationContractDependentValuesToParams(
      current_contract_family_members_per_employee,
      current_contract_number_of_family_members_seats,
    );
    const offerings = organizationPrimaryProductToOfferingTypes(
      current_contract_primary_product,
    );
    const fusionEnabled =
      current_contract_primary_product === PRIMARY_PRODUCT.FUSION;
    const primaryProduct = organizationOfferingTypesToPrimaryProduct(
      offerings,
      fusionEnabled,
    );

    try {
      const orgOptions: CreateSingleOrganizationPayload = {
        country,
        csvDelimiter,
        dependentsPiiEnrollmentEnabled: !!pii_enrollment_enabled,
        enrollment: {
          addOns,
          body: `${body}`,
          campaignDisplayName: campaignDisplayName || null,
          campaignLaunchDate: campaignLaunchDateUTC,
          headline: `${headline}`,
          learnMoreUrl: learn_more_url_path || null,
          overflowUrl: overflow_url,
          restrictBy,
          restrictedDomains,
          slug,
          workIdFieldPlaceholderText: workIdFieldPlaceholderText || null,
        },
        gingerBundlingEnabled,
        gingerId: gingerId === '' ? null : gingerId,
        healthHub: healthHub || null,
        healthHubExternalId:
          healthHubExternalId === '' ? null : healthHubExternalId,
        mfaEnabled,
        name,
        orgMappingKey: orgMappingKey?.trim() ?? null,
        orgMappingValue: orgMappingValue?.trim() || null,
        overlappedCareId,
        parentOrgId,
        salesforceId,
        ssoEnabled,
        subscription: parentOrg
          ? null
          : {
              clinicalCoverageType: current_contract_clinical_coverage_type,
              dependentSeats: dependentValues.dependentSeats,
              dependentsPerMember: dependentValues.dependentsPerMember,
              eapType: current_contract_add_ons
                ? EapTypes.EAP_V2
                : EapTypes.NONE,
              elfDependentsEnabled: !!current_contract_eligibility_file_based_dependents,
              endDate: endDate.toISOString(),
              fusionEnabled,
              isPilot,
              offerings,
              opportunityId,
              partialPayVoucher: current_contract_payment_type
                ? current_contract_voucher
                : '',
              primaryProduct,
              seats: `${assureNumberValue(current_contract_seats)}`,
              startDate: startDate.toISOString(),
            },
        type,
        uploadElfAtChildEnabled,
        useSingleEnrollmentLink,
      };
      const createOrg = await createOrganization({
        ...orgOptions,
      });
      orgId = createOrg.data.id;

      const clinicalCoverageOptions = {
        coverageTotal,
        externalOrgId,
        phoneNumber,
        provider,
        serviceType,
      };

      await handleOrgClinicalCoverage({
        currentContractAddOns: current_contract_add_ons,
        parentOrgContracts: parentOrg?.contracts || [],
        clinicalCoverageOptions,
        orgId,
      });
    } catch (err) {
      dispatch({
        payload: {
          message: `${err}`,
        },
        type: ModalActionsTypes.OPEN_MODAL,
      });
    }
    if (orgId) {
      if (!logoFileError.error && logoFile) {
        try {
          await uploadOrgLogo({ formData, orgId });
        } catch (err) {
          dispatch({
            payload: {
              message:
                'Your organization was created but there was an error uploading the logo. Please try uploading again.',
            },
            type: ModalActionsTypes.OPEN_MODAL,
          });
        }
      }

      dispatch({
        payload: { isFetching: true },
        type: OrganizationsActionsTypes.IS_FETCHING_ORGANIZATIONS,
      });
      const cachedOrg = await getOrganizationById(orgId);
      const nextOrganizationList = OrganizationList.slice();
      nextOrganizationList.push(cachedOrg);
      dispatch({
        payload: { isFetching: false },
        type: OrganizationsActionsTypes.IS_FETCHING_ORGANIZATIONS,
      });
      dispatch({
        payload: { OrganizationList: nextOrganizationList },
        type: OrganizationsActionsTypes.UPDATE_ORGANIZATIONS,
      });
      dispatch({
        payload: {
          cachedOrg: {
            ...cachedOrg,
            admins: [],
            currentContract: cachedOrg.contracts[0] || {},
            enrollment: {
              body,
              campaignDisplayName,
              campaignLaunchDate: campaignLaunchDateUTC,
              headline,
              learnMoreUrl: learn_more_url_path,
              overflowUrl: overflow_url,
              restrictBy,
              restrictedDomains,
              slug,
              workIdFieldPlaceholderText,
            },
            futureContract: cachedOrg.contracts[1] || {},
          },
          orgId,
        },
        type: OrganizationActionsTypes.HANDLE_CHANGE,
      });
      dispatch(saveOrganizationAdministrators());
      dispatch({
        payload: null,
        type: OrganizationActionsTypes.SAVE_ORGANIZATION_ALL_FIELDS,
      });
      await router.navigate(`/orgs/${orgId}/settings`);
    } else {
      dispatch({
        payload: {
          message: `${'Something went wrong! There is a good chance that the organization was created but details may be missing.'}`,
        },
        type: ModalActionsTypes.OPEN_MODAL,
      });
      dispatch(drain());
      await router.navigate(`/orgs`);
    }
  };
};

const handleOrgClinicalCoverage = async ({
  orgId,
  currentContractAddOns,
  parentOrgContracts,
  clinicalCoverageOptions,
}) => {
  const hasCoverageOptions = !!(
    clinicalCoverageOptions.externalOrgId ||
    clinicalCoverageOptions.phoneNumber ||
    clinicalCoverageOptions.provider ||
    clinicalCoverageOptions.serviceType
  );

  const eapTypeV2Exists = parentOrgContracts.some(
    (contract) => contract.eapType === EapTypes.EAP_V2,
  );
  const parentHasClinicalCoverage = parentOrgContracts.some(
    (contract) => contract.clinicalCoverageType !== CLINICAL_COVERAGE_TYPE.NONE,
  );

  const eapContractExists = currentContractAddOns || eapTypeV2Exists;

  if (eapContractExists && hasCoverageOptions) {
    await createOrgEAPCoverage(orgId, { ...clinicalCoverageOptions });
  } else if (hasCoverageOptions && parentHasClinicalCoverage) {
    await createOrgClinicalCoverage(orgId, { ...clinicalCoverageOptions });
  }
};
