import { assureNumberValue } from '../../../../utils/typeMassage';
import { PRIMARY_PRODUCT } from '../../constants';
import {
  CAMPAIGN_DISPLAY_SUPPORTED_RESTRICTBY_TYPES,
  Subscription,
} from '../../../../types/organization';
import { Contract } from '../../../../state/actions/organizationActions';
import { Contract as OrgContractModel } from '../../../../state/models/organization/Contract';

export function generateFamilyMembersPerEmployeeOptions(
  amount: number,
): Array<Option> {
  return Array.from(
    Array(amount),
    (_, index: number): Option => ({
      title: `${index + 1}`,
      value: `${index + 1}`,
    }),
  );
}

export const isZeroInput = (input: string | number): boolean => {
  const numberInput: number = assureNumberValue(input);

  return numberInput === 0;
};

export function shouldShowCampaignDisplayFields(
  restrictByType: string,
  isCSM: boolean,
): boolean {
  return (
    CAMPAIGN_DISPLAY_SUPPORTED_RESTRICTBY_TYPES.includes(restrictByType) &&
    isCSM
  );
}

export function isContractFusionOrUnified(primaryProduct: string): boolean {
  return [PRIMARY_PRODUCT.UNIFIED, PRIMARY_PRODUCT.FUSION].includes(
    PRIMARY_PRODUCT[primaryProduct],
  );
}

export const futureContractInviteBasedDependents = (
  inactiveContract: Subscription | undefined,
  currentContract: Contract,
): boolean => {
  if (inactiveContract) {
    return !isZeroInput(inactiveContract.dependentSeats);
  }
  return !isZeroInput(currentContract.dependentSeats);
};

export const futureContractElfBasedDependents = (
  inactiveContract: Subscription | undefined,
  currentContract: Contract,
): boolean => {
  if (inactiveContract) {
    return inactiveContract.elfDependentsEnabled;
  }
  return currentContract.elfDependentsEnabled;
};

export const isContractProductTypeAllowed = (
  primaryProduct: PRIMARY_PRODUCT,
  knownContracts: {
    contracts?: OrgContractModel[];
    expiredContracts?: OrgContractModel[];
  },
  unifiedOrgCreation: boolean,
): boolean => {
  const allKnownContracts = [
    ...(knownContracts.contracts ?? []),
    ...(knownContracts.expiredContracts ?? []),
  ];

  // is fusion enabled
  if (
    allKnownContracts.some(
      (contract) => contract.primaryProduct === PRIMARY_PRODUCT.FUSION,
    )
  )
    return primaryProduct === PRIMARY_PRODUCT.FUSION;

  // is unified enabled
  if (
    allKnownContracts.some(
      (contract) => contract.primaryProduct === PRIMARY_PRODUCT.UNIFIED,
    )
  )
    return [PRIMARY_PRODUCT.UNIFIED, PRIMARY_PRODUCT.FUSION].includes(
      primaryProduct,
    );

  // is headspace-only or during org creation
  if (unifiedOrgCreation) {
    return [
      PRIMARY_PRODUCT.HEADSPACE,
      PRIMARY_PRODUCT.UNIFIED,
      PRIMARY_PRODUCT.FUSION,
    ].includes(primaryProduct);
  }

  return [PRIMARY_PRODUCT.HEADSPACE, PRIMARY_PRODUCT.FUSION].includes(
    primaryProduct,
  );
};
