import { css } from '@emotion/react';
import {
  fontSize6XL,
  fontSizeL,
  fontSizeS,
  fontSizeXL,
  lineHeightHeading,
  Orange,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { bold } from '../../../../dashboard/styles/fonts';

export const textContainer = css({
  '& div': {
    paddingRight: rem(1.25),
  },
  display: 'flex',
  fontSize: fontSizeL,
  marginTop: rem(1.25),
});

export const joinMessageStyle = css({
  color: WarmGrey[500],
  display: 'flex',
  flexDirection: 'column',
  maxWidth: rem(24.688),
  paddingLeft: SpacingInRemBySize['2XL'],
  paddingRight: SpacingInRemBySize['2XL'],
});

export const subHeaderStyle = css(bold, {
  color: WarmGrey[500],
  fontSize: fontSizeXL,
  lineHeight: lineHeightHeading,
  marginTop: SpacingInRemBySize.M,
});

export const headerStyle = css(bold, {
  color: WarmGrey[500],
  fontSize: fontSize6XL,
  lineHeight: lineHeightHeading,
  margin: '0',
});

export const additionalText = css({
  '& a': {
    color: Orange[200],
    fontSize: fontSizeS,
    textDecoration: 'none',
  },
  '& span': {
    width: rem(24.688),
    wordWrap: 'break-word',
  },
  display: 'flex',

  fontSize: fontSizeS,
  marginTop: rem(1.25),
});
