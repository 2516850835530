import { useState, useCallback, useEffect, FC, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormType,
  LOGIN_FORM_TYPE,
  SIGNUP_FORM_TYPE,
} from '@headspace/web-auth';
import { Spinner } from '@headspace/web-ui-components';
import { ConfiguredLoginAndSignupForm } from '../AuthComponent';
import { FormContainer } from '../shared-components/FormContainer/FormContainer';
import MobileViewJoinMessage from './MobileViewJoinMessage/MobileViewJoinMessage';
import { containerStyle } from './styles';
import { determineLoginRedirectLocation } from '../../state/actions/memberEnrollActions/memberEnrollActions';
import { SSOLoginView } from './SSOLoginView/SSOLoginView';
import {
  signUpScreenViewAction,
  loginScreenViewAction,
  flowStartScreenViewAction,
} from '../../../../analytics/events/enrollmentEvents';
import {
  ExistingAccountCard,
  ExistingAccountCardLabels,
} from './LoginView/ExistingAccountCard';
import { determineHealthProviderByPathname } from '../../../../utils/healthProvider';
import { getConnection } from '../../state/selectors/orgSectionSelector/orgSectionSelector';
import { getEmailVerificationToken } from '../../state/selectors/emailVerificationSelector/emailVerificationSelector';
import { getLanguage } from '../../state/selectors/languageSelector/languageSelector';

export interface MemberLoginProps {
  showSpinner?: boolean;
  showMobileMessage?: boolean;
  onShowPrompt?: (showPromt: boolean) => void;
  /** The page the component will be rendered on. This helps differentiate each instance from one another specifically since the component shares a single redirect saga */
  pageName?: string;
  termsComponentOverride?: ReactElement;
  existingAccountCardLabelOverrides?: ExistingAccountCardLabels;
}

export const MemberLogin: FC<MemberLoginProps> = ({
  showSpinner = false,
  showMobileMessage = true,
  onShowPrompt,
  pageName,
  termsComponentOverride,
  existingAccountCardLabelOverrides,
}) => {
  const emailVerificationToken = useSelector(getEmailVerificationToken);
  const dispatch = useDispatch();
  const connection = useSelector(getConnection);
  const language = useSelector(getLanguage);
  const { pathname, search } = useLocation();
  const hasExistingAccount = search.includes('existing=true');
  const [isJoinMessageOpen, setCloseMessage] = useState(true);
  const initialFormToShow =
    hasExistingAccount || emailVerificationToken
      ? LOGIN_FORM_TYPE
      : SIGNUP_FORM_TYPE;
  const [formToShow, setFormToShow] = useState(initialFormToShow);
  const handleFormChange = useCallback((nextFormToShow: FormType) => {
    setFormToShow(nextFormToShow);
  }, []);
  const healthProvider = determineHealthProviderByPathname(pathname);
  const isSSOEnrollment = healthProvider !== undefined;

  const handleClose = () => setCloseMessage(false);

  useEffect(() => {
    dispatch(
      determineLoginRedirectLocation(isSSOEnrollment, healthProvider, pageName),
    );
  }, [dispatch, healthProvider, pageName, isSSOEnrollment]);

  const [showPrompt, setShowPrompt] = useState(!hasExistingAccount);

  // Analytics actions
  useEffect(() => {
    if (!connection && !showPrompt) {
      dispatch(
        formToShow === LOGIN_FORM_TYPE
          ? loginScreenViewAction(healthProvider)
          : signUpScreenViewAction(healthProvider),
      );
    }
  }, [connection, showPrompt, formToShow, dispatch, healthProvider]);

  useEffect(() => {
    dispatch(flowStartScreenViewAction(healthProvider));
  }, [dispatch, healthProvider]);

  // Since we don't have routes within this member component
  // to be able to keep users on the website after them clicking the back button
  // we inject an empty page and listen to back button click to navigate them to
  // the first page of the current flow.
  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    const initialPageUrl = window.location.href;
    // perform redirect to initial page instead of leaving the website.
    window.location.href = initialPageUrl;
  };

  const handleClickExistingAccount = useCallback(
    (formToShow: FormType) => {
      setFormToShow(formToShow);
      setShowPrompt(false);
      onShowPrompt && onShowPrompt(false);
    },
    [setFormToShow, setShowPrompt, onShowPrompt],
  );

  let component: JSX.Element;
  if (connection) {
    component = <SSOLoginView connection={connection} />;
  } else if (showPrompt) {
    component = (
      <ExistingAccountCard
        handleClick={handleClickExistingAccount}
        {...existingAccountCardLabelOverrides}
      />
    );
  } else {
    component = (
      <ConfiguredLoginAndSignupForm
        language={language}
        onFormChange={handleFormChange}
        initialFormToShow={formToShow}
        termsComponentOverride={termsComponentOverride}
      />
    );
  }

  return (
    <div>
      {showSpinner ? (
        <Spinner />
      ) : (
        <>
          <div css={containerStyle(isJoinMessageOpen && showMobileMessage)}>
            <FormContainer
              showTerms={false}
              termsComponentOverride={termsComponentOverride}
            >
              {component}
            </FormContainer>
          </div>

          {showMobileMessage && (
            <MobileViewJoinMessage
              isOpen={isJoinMessageOpen}
              handleClose={handleClose}
            />
          )}
        </>
      )}
    </div>
  );
};
